import { formatUtcToLocal, isDate } from '@oplog/express';

export const EXCEL_EXPORT_LIMIT = 100000; // Changed based on data received from back-end team.
const EXCEL_SALESORDER_LINEITEM_AVERAGE = 3;
const EXCEL_RETURNS_LINEITEM_AVERAGE = 4;
const EXCEL_RECEIVINGORDER_LINEITEM_AVERAGE = 500;

export const EXCEL_EXPORT_SALESORDER_LIMIT = 5000;
// Math.round(EXCEL_EXPORT_LIMIT / EXCEL_SALESORDER_LINEITEM_AVERAGE) this was not used because the number was something like 33.333
export const EXCEL_EXPORT_RETURNS_LIMIT = Math.round(EXCEL_EXPORT_LIMIT / EXCEL_RETURNS_LINEITEM_AVERAGE);
export const EXCEL_EXPORT_RECEIVINGORDER_LIMIT = Math.round(EXCEL_EXPORT_LIMIT / EXCEL_RECEIVINGORDER_LINEITEM_AVERAGE);

export const checkShippingAddress = (address: string) => {
  if (address) {
    const isThereUndefined = address.toLowerCase().includes('undefined');
    if (isThereUndefined) {
      const removeTypes: string[] = ['undefined', 'Undefined'];
      let newAddress;
      removeTypes.forEach(type => {
        newAddress = address.replace(type, '');
      });
      return newAddress;
    }
  }

  return address;
};
export const dateToString = (date?: Date, dateFormat?: string) => {
  if (date && isDate(date) && dateFormat === undefined) {
    return formatUtcToLocal(date);
  } else if (date && isDate(date) && dateFormat) {
    return formatUtcToLocal(date, dateFormat);
  }

  return 'N/A';
};
