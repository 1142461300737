import {
  GeneralErrorPanel,
  MainContent,
  SalesOrderBlockedGrid,
  SalesOrderQuarantineGrid,
  SalesOrdersGrid,
} from '@components';
import { FileUploader } from '@components/molecules/FileUploader/FileUploader';
import { MemberPageContainer } from '@containers/MemberPageContainer';
import { SupportSalesOrderCancelModalContainer } from '@containers/SupportSalesOrderCancelModalContainer';
import { ResourceType } from '@models';
import {
  ActionBar,
  BarChart,
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Panel,
  PanelTitle,
  Popover,
  Tab,
  Widget,
  WidgetThreeProps,
  WidgetThreeStep,
  Text,
} from '@oplog/express';
import { urls } from '@routes/urls';
import {
  SalesOrderLongTermReportOutputDTO,
  SalesOrderReportDetailsOutputDTO,
  SalesOrderState,
  TenantDetailsDTO,
  WeeklyReportOutputDTO,
} from '@services';
import useCommonDataStore from '@store/commonData/commonDataState';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import history from '../../../history';
import { tracking } from '../../../utils/analytics';
import { Props } from '../../atoms/Component/Component';
import useForceUpdate from 'use-force-update';

export interface SalesOrdersProps extends Props {
  salesOrdersReports?: {
    weekly: WeeklyReportOutputDTO;
    longterm: SalesOrderLongTermReportOutputDTO;
  };
  errorWeeklyReport?: any;
  errorLongTermReport?: any;
  onCreateSalesOrderClickHandler: () => void;
  getSalesOrderReports: () => void;
  onClick: ({ state, date, activeTab }: { state?: string; date?: Moment; activeTab?: number }) => void;
  isUploading: boolean;
  urlTabId: string;
  onReset: () => void;
  goTab: (tab: string) => void;
  onFileSelect: (file: File) => void;
  onExcelUploadClose: () => void;
  gridFilters?: any;
  gridFiltersBlocked?: any;
  excelToExport: (resourceType: ResourceType) => void;
  excelToExportInit: () => void;
  excelExportResource: any;
  excelExportDetailedResource: any;
  blockedExcelToExport: (resourceType: ResourceType) => void;
  blockedExcelToExportInit: () => void;
  blockedExcelExportResource: any;
  blockedExcelExportDetailedResource: any;
  tenantDetails: TenantDetailsDTO;
  getTenantDetails: () => void;
}

export enum SalesOrderTabs {
  Orders = 'Orders',
  Quarantine = 'Quarantine',
  Blocked = 'Blocked',
}

const COMPONENT_INTL_KEY = 'SalesOrders';
const ORDER_WITH_EXTERNAL_CUSTOMER_ID_EXCEL_TEMPLATE_KEY = "FileTemplateNameV2";

export const SalesOrders: React.FC<SalesOrdersProps> = ({
  salesOrdersReports,
  getSalesOrderReports,
  onExcelUploadClose,
  intl,
  urlTabId,
  errorWeeklyReport,
  isBusy,
  errorLongTermReport,
  onClick,
  onCreateSalesOrderClickHandler,
  gridFilters,
  gridFiltersBlocked,
  goTab,
  onWillUnmount,
  excelToExport,
  excelToExportInit,
  excelExportResource,
  excelExportDetailedResource,
  blockedExcelToExport,
  blockedExcelToExportInit,
  blockedExcelExportResource,
  blockedExcelExportDetailedResource,
  tenantDetails,
  getTenantDetails,
}) => {
  const [excelModalOpen, setExcelModalOpen] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [isCancelAllowed, setIsCancelAllowed] = useState(false);
  const [salesOrdersPackageDetailExcelExportLimit, setSalesOrdersPackageDetailExcelExportLimit] = useState(0);
  const [salesOrdersBlockedPackageDetailExcelExportLimit, setSalesOrdersBlockedPackageDetailExcelExportLimit] = useState(0);
  const [isExternalCustomerIdAllowed, setIsExternalCustomerIdAllowed] = useState(false);
  const [tenantName, setTenantName] = useState('');

  const [{ integrations }, { setIntegrations }] = useCommonDataStore();

  useEffect(() => {
    // This is done because grid data is received by dqb and from url path. Initally in login the path is '/' which creates a problem for Data Grid.
    // This was done to fix this issue: https://oplog.atlassian.net/browse/OMS-1142
    if (history.location.pathname === '/') {
      history.replace(urls.salesOrders);
    }
    getTenantDetails();
    getSalesOrderReports();
    const index = Object.keys(SalesOrderTabs).findIndex(x => x === urlTabId);
    setCurrentTab(index === -1 ? 0 : index);
    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  useEffect(() => {
    if (tenantDetails) {
      setIsCancelAllowed(tenantDetails.isUIBulkCancelBlockedAllowed);
      setSalesOrdersPackageDetailExcelExportLimit(tenantDetails.salesOrdersPackageDetailExcelExportLimit);
      setSalesOrdersBlockedPackageDetailExcelExportLimit(tenantDetails.salesOrdersBlockedPackageDetailExcelExportLimit);
      setIsExternalCustomerIdAllowed(tenantDetails.isExternalCustomerIdAllowed);
      setTenantName(tenantDetails.name);
    }
  }, [tenantDetails]);

  const populateWeeklyChartData = () => {
    const chartData: any[] | undefined = [];
    if (salesOrdersReports?.weekly) {
      const { weekly } = salesOrdersReports;
      if (weekly && weekly.report) {
        const count = weekly.report.length;
        weekly.report.forEach((week, index) => {
          chartData.push({
            name: index == count - 1 ? intl.messages[`${COMPONENT_INTL_KEY}.Days.Today`] : intl.messages[`${COMPONENT_INTL_KEY}.Days.${week.dayOfWeek}`],
            value: week.count,
          });
        });
      }
    }

    return chartData;
  };

  const changeExcelUploadModalOpen = () => {
    setExcelModalOpen(!excelModalOpen);
    if (excelModalOpen) {
      onExcelUploadClose();
    } else {
      tracking.emitEvent('CreateExcelSalesOrder', 'UploadModalOpen');
    }
  };

  const cancelModalClose = () => {
    setCancelModalOpen(false);
    window.location.reload();
  };

  const changeCancelModalOpen = () => {
    setCancelModalOpen(!isCancelModalOpen);
  };

  const renderCancelModal = () => {
    if (isCancelModalOpen) {
      return <SupportSalesOrderCancelModalContainer isCancelBlockedOnlyActive={true} onClose={cancelModalClose} />;
    }
    return null;
  };

  const excelUploadSuccess = (response: any) => {
    tracking.emitEvent('CreateExcelSalesOrder', 'UploadSuccess');
  };

  const renderOrderSummaryBox = (orderCount: number, day: string, orderStateOrderCounts: any) => {
    let titleText = '';
    let orderFilterDate: Moment;

    if (day === 'today') {
      titleText = intl.messages[`${COMPONENT_INTL_KEY}.InfoBoxMessages.TodaysOrders`];
      orderFilterDate = moment();
    } else {
      titleText = intl.formatMessage(
        { id: `${COMPONENT_INTL_KEY}.InfoBoxMessages.OrdersReceivedDaysAgo` },
        { days: day }
      );
      orderFilterDate = moment().subtract(day, 'days');
    }

    const orderSummaryBoxProps: WidgetThreeProps = {
      title: titleText,
      value: `${orderCount}`,
      ...(orderCount && {
        onClick: () => {
          onClick({ date: orderFilterDate, activeTab: currentTab })
        },
      }),
      steps: orderStateOrderCounts,
      isLoading: isBusy,
    };

    return <Widget.Three {...orderSummaryBoxProps} />;
  };

  const setStatesOrderCounts = (
    day: number,
    orderReportDetail?: SalesOrderReportDetailsOutputDTO
  ): WidgetThreeStep[] | [] => {
    if (orderReportDetail) {
      const date = moment().subtract(day, 'days');
      return [
        {
          text: `${orderReportDetail.cancelledCount}`,
          color: 'red',
          tooltip: {
            content: intl.messages['Enum.Cancelled'],
            placement: 'bottom',
            action: ['hover', 'focus'],
          },
          ...(orderReportDetail.cancelledCount && {
            onClick: () => {
              onClick({
                state: SalesOrderState.Cancelled.toString(),
                date,
              });
            },
          }),
        },
        {
          text: `${orderReportDetail.createdCount}`,
          color: 'snow',
          tooltip: {
            content: intl.messages['Enum.Created'],
            placement: 'bottom',
            action: ['hover', 'focus'],
          },
          ...(orderReportDetail.createdCount && {
            onClick: () => {
              onClick({
                state: SalesOrderState.Created.toString(),
                date,
              });
            },
          }),
        },
        {
          text: `${orderReportDetail.pickedCount}`,
          color: 'blue',
          tooltip: {
            content: intl.messages['Enum.Picked'],
            placement: 'bottom',
            action: ['hover', 'focus'],
          },
          ...(orderReportDetail.pickedCount && {
            onClick: () => {
              onClick({
                state: SalesOrderState.Picked.toString(),
                date,
              });
            },
          }),
        },
        {
          text: `${orderReportDetail.packedCount}`,
          color: 'purple',
          tooltip: {
            content: intl.messages['Enum.Packed'],
            placement: 'bottom',
            action: ['hover', 'focus'],
          },
          ...(orderReportDetail.packedCount && {
            onClick: () => {
              onClick({
                state: SalesOrderState.Packed.toString(),
                date,
              });
            },
          }),
        },
        {
          text: `${orderReportDetail.readyToBeShippedCount}`,
          color: 'violet',
          tooltip: {
            content: intl.messages['Enum.ReadyToBeShipped'],
            placement: 'bottom',
            action: ['hover', 'focus'],
          },
          ...(orderReportDetail.shippedCount && {
            onClick: () => {
              onClick({
                state: SalesOrderState.ReadyToBeShipped.toString(),
                date,
              });
            },
          }),
        },
        {
          text: `${orderReportDetail.shippedCount}`,
          color: 'orange',
          tooltip: {
            content: intl.messages['Enum.Shipped'],
            placement: 'bottom',
            action: ['hover', 'focus'],
          },
          ...(orderReportDetail.shippedCount && {
            onClick: () => {
              onClick({
                state: SalesOrderState.Shipped.toString(),
                date,
              });
            },
          }),
        },
        {
          text: `${orderReportDetail.deliveredCount}`,
          color: 'green',
          tooltip: {
            content: intl.messages['Enum.Delivered'],
            placement: 'bottom',
            action: ['hover', 'focus'],
          },
          ...(orderReportDetail.deliveredCount && {
            onClick: () => {
              onClick({
                state: SalesOrderState.Delivered.toString(),
                date,
              });
            },
          }),
        },
      ];
    }
    return [];
  };

  const renderLongTermArea = () => {
    if (errorLongTermReport !== undefined) {
      return (
        <Box width={1} pb="16">
          <GeneralErrorPanel className="align-left" />
        </Box>
      );
    }

    if (isBusy || (salesOrdersReports && salesOrdersReports.longterm)) {
      const { longterm } = salesOrdersReports!;

      const todayOrderCount = isBusy ? 0 : longterm.today && longterm.today.totalOrderCount;
      const lastSevenDaysOrderCount = isBusy ? 0 : longterm.lastSevenDays && longterm.lastSevenDays.totalOrderCount;
      const lastThirtyDaysOrderCount = isBusy ? 0 : longterm.lastThirtyDays && longterm.lastThirtyDays.totalOrderCount;
      const lastNinetyDaysOrderCount = isBusy ? 0 : longterm.lastNinetyDays && longterm.lastNinetyDays.totalOrderCount;

      const todaysStateOrderCounts = setStatesOrderCounts(0, isBusy ? undefined : longterm.today);
      const lastSevenDaysStateOrderCounts = setStatesOrderCounts(7, isBusy ? undefined : longterm.lastSevenDays);
      const lastThirtyDaysStateOrderCounts = setStatesOrderCounts(30, isBusy ? undefined : longterm.lastThirtyDays);
      const lastNinetyDaysStateOrderCounts = setStatesOrderCounts(90, isBusy ? undefined : longterm.lastNinetyDays);

      return (
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1, 1 / 2]} pb="22" pr={['0', '0', '0', '11']}>
            {renderOrderSummaryBox(todayOrderCount as number, 'today', todaysStateOrderCounts)}
          </Box>
          <Box width={[1, 1, 1, 1 / 2]} pb={['22', '22', '22', '0']} pl={['0', '0', '0', '11']}>
            {renderOrderSummaryBox(lastSevenDaysOrderCount as number, '7', lastSevenDaysStateOrderCounts)}
          </Box>
          <Box width={[1, 1, 1, 1 / 2]} pb={['22', '22', '22', '44']} pr={['0', '0', '0', '11']}>
            {renderOrderSummaryBox(lastThirtyDaysOrderCount as number, '30', lastThirtyDaysStateOrderCounts)}
          </Box>
          <Box width={[1, 1, 1, 1 / 2]} pb={['22', '22', '44', '44']} pl={['0', '0', '0', '11']}>
            {renderOrderSummaryBox(lastNinetyDaysOrderCount as number, '90', lastNinetyDaysStateOrderCounts)}
          </Box>
        </Flex>
      );
    }

    return undefined;
  };

  const breadcrumb = [{ title: intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`] }];

  return (
    <MemberPageContainer
      key="member-page-sales-orders"
      id="member-page-sales-orders"
      documentTitle={intl.messages['SalesOrders.Header.Title']}
    >
      <ActionBar
        title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}
        subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Subtitle`]}
        breadcrumb={breadcrumb}
        top="66px"
      >
        <Button
          variant="dark"
          marginLeft="auto"
          dropdown={{
            items: [
              {
                text: intl.messages[`${COMPONENT_INTL_KEY}.CreateSalesOrderDropdown.CreateSalesOrderManually`],
                value: intl.messages[`${COMPONENT_INTL_KEY}.CreateSalesOrderDropdown.CreateSalesOrderManually`],
                onClick: () => onCreateSalesOrderClickHandler(),
              },
              {
                text: intl.messages[`${COMPONENT_INTL_KEY}.CreateSalesOrderDropdown.CreateSalesOrderByExcel`],
                value: intl.messages[`${COMPONENT_INTL_KEY}.CreateSalesOrderDropdown.CreateSalesOrderByExcel`],
                onClick: changeExcelUploadModalOpen,
              },
            ],
          }}
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.CreateSalesOrder`]}
        </Button>
      </ActionBar>
      <MainContent>
        <Box width={1}>
          <Flex display="flex" flexDirection="row" flexWrap="wrap">
            <Box width={[1, 1, 1 / 2, 2 / 3]} pr="22">
              <Panel>
                <Flex justifyContent="space-between">
                  <PanelTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Header.Panel.OrderInformation`]}</PanelTitle>
                </Flex>
                {renderLongTermArea()}
              </Panel>
            </Box>
            <Box
              width={[1, 1, 1 / 2, 1 / 3]}
              pl={['0', '0', '22', '22']}
              pb={['44', '44', '0', '0']}
              pt={['22', '22', '0', '0']}
              pr={['22', '22', '0', '0']}
            >
              <Panel
                key="panel-chart"
                className="chart-panel"
                title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Panel.OrderDistributionPerWeekday`]}
              >
                {errorWeeklyReport !== undefined ? (
                  <GeneralErrorPanel />
                ) : (
                  <Box ml="-45px">
                    <BarChart
                      data={populateWeeklyChartData()}
                      columnColor="#abb4eb"
                      height={208}
                      isLegendVisible={false}
                      isTooltipVisible
                    />
                  </Box>
                )}
              </Panel>
            </Box>
          </Flex>
        </Box>
        <Box>
          <Tab
            onTabChange={data => {
              setCurrentTab(data);
              goTab(Object.values(SalesOrderTabs)[data]);
            }}
            activeIndex={currentTab}
            tabs={[
              {
                id: SalesOrderTabs.Orders,
                title: (
                  <Popover
                    content={
                      <Text fontFamily="Lato" fontSize="16">
                        {intl.messages[`${COMPONENT_INTL_KEY}.Tabs.OrdersMessage`]}
                      </Text>
                    }
                    isDark
                    withPortal
                    placement="top"
                    action={['hover']}
                  >
                    <Text>{intl.messages[`${COMPONENT_INTL_KEY}.Tabs.Orders`]}</Text>
                  </Popover>
                ),
                component: (
                  <SalesOrdersGrid
                    filters={gridFilters}
                    intl={intl}
                    integrations={integrations}
                    excelToExport={excelToExport}
                    excelExportResource={excelExportResource}
                    excelExportDetailedResource={excelExportDetailedResource}
                    excelToExportInit={excelToExportInit}
                    salesOrdersPackageDetailExcelExportLimit={salesOrdersPackageDetailExcelExportLimit}
                  />
                ),
              },
              {
                id: SalesOrderTabs.Quarantine,
                title: (
                  <Popover
                    content={
                      <Text fontFamily="Lato" fontSize="16">
                        {intl.messages[`${COMPONENT_INTL_KEY}.Tabs.QuarantineMessage`]}
                      </Text>
                    }
                    isDark
                    withPortal
                    placement="top"
                    action={['hover']}
                  >
                    <Text>{intl.messages[`${COMPONENT_INTL_KEY}.Tabs.Quarantine`]}</Text>
                  </Popover>
                ),
                component: <SalesOrderQuarantineGrid intl={intl} integrations={integrations} />,
              },
              {
                id: SalesOrderTabs.Blocked,
                title: (
                  <Popover
                    content={
                      <Text fontFamily="Lato" fontSize="16">
                        {intl.messages[`${COMPONENT_INTL_KEY}.Tabs.BlockedMessage`]}
                      </Text>
                    }
                    isDark
                    withPortal
                    placement="top"
                    action={['hover']}
                  >
                    <Text>{intl.messages[`${COMPONENT_INTL_KEY}.Tabs.Blocked`]}</Text>
                  </Popover>
                ),
                component: (
                  <>
                    {isCancelAllowed && (
                      <Button
                        variant="dark"
                        marginLeft="auto"
                        marginBottom="20px"
                        kind="outline"
                        onClick={changeCancelModalOpen}
                      >
                        <Text>{intl.messages[`${COMPONENT_INTL_KEY}.Buttons.CancelSalesOrders`]}</Text>
                      </Button>
                    )}

                    <SalesOrderBlockedGrid
                      filters={gridFiltersBlocked}
                      intl={intl}
                      integrations={integrations}
                      blockedExcelToExport={blockedExcelToExport}
                      blockedExcelExportResource={blockedExcelExportResource}
                      blockedExcelExportDetailedResource={blockedExcelExportDetailedResource}
                      blockedExcelToExportInit={blockedExcelToExportInit}
                      blockedSalesOrdersPackageDetailExcelExportLimit={salesOrdersBlockedPackageDetailExcelExportLimit}
                    />
                  </>
                ),
              },
            ]}
          />
        </Box>
      </MainContent>
      {excelModalOpen && (
        <Modal
          onClose={changeExcelUploadModalOpen}
          isOpen={excelModalOpen}
          size="xl"
          disableEscapeButtonClose={true}
          disableOutsideMouseEvents={true}
        >
          <ModalHeader>
            <ModalTitle>
              {intl.messages[`${COMPONENT_INTL_KEY}.CreateSalesOrderDropdown.CreateSalesOrderByExcel`]}
            </ModalTitle>
          </ModalHeader>
          <ModalContent>
            <FileUploader
              intl={intl}
              uploadFileType={COMPONENT_INTL_KEY}
              apiUrlExtension="/api/SalesOrders.importFromExcel"
              fileType=".xls,.xlsx"
              onClose={changeExcelUploadModalOpen}
              onSuccess={excelUploadSuccess}
              customFileTemplateKey={isExternalCustomerIdAllowed ? ORDER_WITH_EXTERNAL_CUSTOMER_ID_EXCEL_TEMPLATE_KEY : undefined}
            />
          </ModalContent>
        </Modal>
      )}
      {renderCancelModal()}
    </MemberPageContainer>
  );
};
