import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { GridAction } from '@oplog/data-grid/dist/store/actions';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { SalesOrderTabs, SalesOrders, SalesOrdersProps } from '@pages/SalesOrders';
import { urls } from '@routes/urls';
import { StoreState } from '@store';
import { onTabChange } from '@utils';
import { push, RouterAction } from 'connected-react-router';
import {
  DateFilter,
  DateFilterOperation,
  Filter,
  StringFilter,
  StringFilterOperation,
} from 'dynamic-query-builder-client';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

export function mapStateToProps(
  { resources, grid }: StoreState,
  props: RouteComponentProps<any>
): Partial<SalesOrdersProps> {
  return {
    urlTabId: props.match.params.tab,
    salesOrdersReports: {
      weekly: resourceSelectors.getData(resources, ResourceType.SalesOrderWeeklyReport),
      longterm: resourceSelectors.getData(resources, ResourceType.SalesOrderLongTermReport),
    },
    gridFilters: grid.salesOrders,
    gridFiltersBlocked: grid.salesOrderBlocked,
    isBusy:
      resourceSelectors.isBusy(resources, ResourceType.SalesOrderWeeklyReport) ||
      resourceSelectors.isBusy(resources, ResourceType.SalesOrderLongTermReport),
    errorLongTermReport: resourceSelectors.getError(resources, ResourceType.SalesOrderLongTermReport),
    errorWeeklyReport: resourceSelectors.getError(resources, ResourceType.SalesOrderWeeklyReport),
    //integrations: resourceSelectors.getData(resources, ResourceType.GetIntegrations),
    excelExportResource: resourceSelectors.getResource(resources, ResourceType.SalesOrderExcelExport),
    excelExportDetailedResource: resourceSelectors.getResource(resources, ResourceType.SalesOrderExcelDetailedExport),
    blockedExcelExportResource: resourceSelectors.getResource(resources, ResourceType.PendingSalesOrderExcelExport),
    blockedExcelExportDetailedResource: resourceSelectors.getResource(
      resources,
      ResourceType.PendingSalesOrderExcelDetailedExport
    ),
    tenantDetails: resourceSelectors.getData(resources, ResourceType.TenantGetDetails),
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | GridAction>,
  routeProps: RouteComponentProps<any>
): Partial<SalesOrdersProps> {
  const longTermDate = new Date();
  longTermDate.setUTCHours(0, 0, 0, 0);
  longTermDate.setDate(longTermDate.getDate() - 90);

  const weeklyDate = new Date();
  weeklyDate.setUTCHours(0, 0, 0, 0);
  weeklyDate.setDate(weeklyDate.getDate() - 7);

  return {
    onCreateSalesOrderClickHandler: () => {
      dispatch(push(urls.createSalesOrder));
    },
    getTenantDetails: () => {
      dispatch(resourceActions.resourceRequested(ResourceType.TenantGetDetails));
    },
    goTab: tab => {
      onTabChange(tab, routeProps, dispatch);
    },
    getSalesOrderReports: () => {
      dispatch(resourceActions.resourceRequested(ResourceType.SalesOrderWeeklyReport, { weeklyDate }));
      dispatch(resourceActions.resourceRequested(ResourceType.SalesOrderLongTermReport, { longTermDate }));
    },
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.SalesOrderWeeklyReport));
      dispatch(resourceActions.resourceInit(ResourceType.SalesOrderLongTermReport));
    },
    onClick: ({ state, date, activeTab }: { state?: string; date?: moment.Moment; activeTab?: number }) => {
      let filters: Filter[] = [];

      const activePath = Object.values(SalesOrderTabs)[activeTab ? activeTab : 0];
      
      if (state) {
        const stateFilter = new StringFilter({
          property: 'state',
          op: StringFilterOperation.Equals,
          value: state,
          id: 'state',
        });
        filters = [stateFilter];
      }
      if (date) {
        const dateFilter = new DateFilter({
          op: DateFilterOperation.GreaterThan,
          value: date.toString(),
          property: 'orderCreatedAt',
        });
        filters = [...filters, dateFilter];
      }
      dispatch(gridActions.gridFiltersSubmitted(GridType.SalesOrders, filters, []));
      dispatch(gridActions.gridFiltersSubmitted(GridType.SalesOrderBlocked, filters, []));

      dispatch(gridActions.gridPaginationOffsetReset(GridType.SalesOrders));
      dispatch(gridActions.gridPaginationOffsetReset(GridType.SalesOrderBlocked));

      switch (activePath) {
        case SalesOrderTabs.Orders:
          dispatch(gridActions.gridFetchRequested(GridType.SalesOrders));
          break;
        case SalesOrderTabs.Blocked:
          dispatch(gridActions.gridFetchRequested(GridType.SalesOrderBlocked));
          break;
        default:
          break;
      }
    },
    onExcelUploadClose: (): void => {
      dispatch(gridActions.gridFetchRequested(GridType.SalesOrders));
    },
    excelToExport: (resourceType: ResourceType) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const dqb = url.searchParams.get('dqb');

      const gridState = JSON.parse(decodeURIComponent(dqb ? dqb : ''));

      dispatch(resourceActions.resourceRequested(resourceType, { dqb: gridState.query }));
    },
    excelToExportInit: () => {
      dispatch(resourceActions.resourceInit(ResourceType.SalesOrderExcelExport));
      dispatch(resourceActions.resourceInit(ResourceType.SalesOrderExcelDetailedExport));
    },
    blockedExcelToExport: (resourceType: ResourceType) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const dqb = url.searchParams.get('dqb');

      const gridState = JSON.parse(decodeURIComponent(dqb ? dqb : ''));

      dispatch(resourceActions.resourceRequested(resourceType, { dqb: gridState.query }));
    },
    blockedExcelToExportInit: () => {
      dispatch(resourceActions.resourceInit(ResourceType.PendingSalesOrderExcelExport));
      dispatch(resourceActions.resourceInit(ResourceType.PendingSalesOrderExcelDetailedExport));
    },
  };
}

export const SalesOrdersContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SalesOrders));
