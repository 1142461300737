import { getTokenSilently } from '@auth/auth0';
import { config } from '@config';
import { GridType, ResourceType } from '@models';
import { GridFetchResult } from '@oplog/data-grid';
import { urls } from '@routes/urls';
import axios from 'axios';
import AuthManagement from '../auth/auth';
import history from '../history';
import {
  CargoCarriersApiFactory,
  CustomersApiFactory,
  FilesApiFactory,
  IntegrationsApiFactory,
  InventoryApiFactory,
  PackagesApiFactory,
  ProductsApiFactory,
  ProvinceApiFactory,
  ReceivingOrdersApiFactory,
  ReturnsApiFactory,
  SalesChannelsApiFactory,
  SalesOrdersApiFactory,
  TenantsApiFactory,
  UserFeedbackApiFactory,
  UsersApiFactory
} from './swagger/index';
export const TENANT_ID_KEY = 'tenantId';

const { url } = config.api;

export function getAuthHeaders() {
  const output: any = {};

  const token = AuthManagement.getToken();
  if (token != null) {
    output.Authorization = `Bearer ${token}`;
  }

  const tenantID = localStorage.getItem(TENANT_ID_KEY);
  if (tenantID) {
    output['X-Tenant-Id'] = tenantID;
  }

  return output;
}

axios.interceptors.request.use(
  async config => {
    const token = await getTokenSilently();
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
      const tenantID = localStorage.getItem(TENANT_ID_KEY);
      if (tenantID) {
        config.headers['X-Tenant-Id'] = tenantID;
      }
      config.headers[`X-Timezone-Offset`] = new Date().getTimezoneOffset().toString();
      AuthManagement.setToken(token);
      return config;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response.data;
  },
  async function (error) {
    if (error.response?.status === 401 || error?.error === 'login_required') {
      localStorage.removeItem(TENANT_ID_KEY);
      history.push(urls.authentication);
    }
    return Promise.reject(error);
  }
);

const api = {
  Products: ProductsApiFactory(undefined, url, axios),
  Customers: CustomersApiFactory(undefined, url, axios),
  InventoryItems: InventoryApiFactory(undefined, url, axios),
  SalesOrders: SalesOrdersApiFactory(undefined, url, axios),
  ReceivingOrders: ReceivingOrdersApiFactory(undefined, url, axios),
  Feedback: UserFeedbackApiFactory(undefined, url, axios),
  Tenants: TenantsApiFactory(undefined, url, axios),
  Integrations: IntegrationsApiFactory(undefined, url, axios),
  Returns: ReturnsApiFactory(undefined, url, axios),
  Packages: PackagesApiFactory(undefined, url, axios),
  Files: FilesApiFactory(undefined, url, axios),
  Users: UsersApiFactory(undefined, url, axios),
  Province: ProvinceApiFactory(undefined, url, axios),
  CargoCarriers: CargoCarriersApiFactory(undefined, url, axios),
  SalesChannels: SalesChannelsApiFactory(undefined, url, axios),
};

const gridApiMap = {
  [GridType.Products]: api.Products.apiProductsQueryGet,
  [GridType.ProductSalesOrderCustomers]: api.Products.apiProductsQueryCustomersGet,
  [GridType.ProductSalesOrders]: api.Products.apiProductsQuerySalesOrdersGet,
  [GridType.ProductReceivingOrders]: api.Products.apiProductsQueryReceivingOrdersGet,
  [GridType.Customers]: api.Customers.apiCustomersQueryGet,
  [GridType.CustomerSalesOrders]: api.Customers.apiCustomersQuerySalesOrdersGet,
  [GridType.CustomerSalesOrderLineItems]: api.Customers.apiCustomersQueryLineItemsGet,
  [GridType.InventoryItems]: api.InventoryItems.apiInventoryQueryGet,
  [GridType.SalesOrders]: api.SalesOrders.apiSalesOrdersQueryGet,
  [GridType.SalesOrderQuarantine]: api.SalesOrders.apiSalesOrdersQueryQuarantineGet,
  [GridType.SalesOrderBlocked]: api.SalesOrders.apiSalesOrdersQueryPendingGet,
  [GridType.SalesOrderLineItems]: api.SalesOrders.apiSalesOrdersQueryLineItemsGet,
  [GridType.InvalidSalesOrderLineItems]: api.SalesOrders.apiSalesOrdersQueryInvalidSalesOrderLineItemsGet,
  [GridType.SalesOrderPackageLineItems]: api.SalesOrders.apiSalesOrdersQueryPackageLineItemsGet,
  [GridType.SalesOrderRecentLineItems]: api.SalesOrders.apiSalesOrdersQueryRecentLineItemsGet,
  [GridType.ReceivingOrders]: api.ReceivingOrders.apiReceivingOrdersQueryGet,
  [GridType.ReceivingOrderLineItems]: api.ReceivingOrders.apiReceivingOrdersQueryLineItemsGet,
  [GridType.ReceivingOrderLineItemExpirationDates]: api.ReceivingOrders.apiReceivingOrdersQueryLineItemExpirtionDatesGet,
  [GridType.Returns]: api.Returns.apiReturnsQueryGet,
  [GridType.ReturnsDamaged]: api.Returns.apiReturnsQueryDamagedProductsGet,
  [GridType.ReturnLineItems]: api.Returns.apiReturnsQueryLineItemsGet,
  [GridType.CustomerShippingAddresses]: api.Customers.apiCustomersQueryAddressesGet,
  [GridType.Users]: api.Users.apiUsersQueryGet,
  [GridType.SupportProducts]: api.Products.adminProductsQueryGet,
  [GridType.SupportSalesOrders]: api.SalesOrders.adminSalesOrdersQueryOrdersOfTenantGet,
  [GridType.SupportReceivingOrders]: api.ReceivingOrders.adminReceivingOrdersQueryOrdersOfTenantGet,
  [GridType.SalesOrdersReturnable]: api.SalesOrders.apiSalesOrdersQueryReturnableGet,
  [GridType.InventoryItemsExportToExcel]: api.InventoryItems.apiInventoryExportToExcelGet,
  [GridType.InventoryQuarantineExportToExcel]: api.InventoryItems.apiInventoryExportToExcelQuarantineGet,
  [GridType.ProductQuarantineExportToExcel]: api.Products.apiProductsExportToExcelquarantineGet,
  [GridType.SalesOrdersExportToExcel]: api.SalesOrders.apiSalesOrdersExportToExcelGet,
  [GridType.SalesOrderQuarantineExportToExcel]: api.SalesOrders.apiSalesOrdersExportInvalidSalesOrderToExcelGet,
  [GridType.SalesOrderBlockedExportToExcel]: api.SalesOrders.apiSalesOrdersExportPendingdSalesOrderToExcelGet,
  [GridType.CustomersExportToExcel]: api.Customers.apiCustomersExportToExcelGet,
  [GridType.ProductsExportToExcel]: api.Products.apiProductsExportToExcelGet,
  [GridType.ReceivingOrdersExportToExcel]: api.ReceivingOrders.apiReceivingOrdersExportToExcelGet,
  [GridType.ReceivingOrderLineItemsExportToExcel]: api.ReceivingOrders.apiReceivingOrdersExportToExcelLineItemsGet,
  [GridType.ReturnsExportToExcel]: api.Returns.apiReturnsExportToExcelGet,
  [GridType.SupportPackages]: api.Packages.adminPackagesQueryPackagesOfTenantGet,
  [GridType.ProductQuarantine]: api.Products.apiProductsQueryQuarantineGet,
  [GridType.InventoryQuarantine]: api.InventoryItems.apiInventoryQueryMissingsGet,
  [GridType.InventoryItemExpirationDates]: api.InventoryItems.apiInventoryQueryExpirationDatesGet,
  [GridType.InventoryItemExpirationDatesExportToExcel]: api.InventoryItems.apiInventoryExportToExcelExpirationDatesGet,
  [GridType.InventoryItemSerialNumbers]: api.InventoryItems.apiInventoryQuerySerialNumbersGet,
  [GridType.InventoryItemSerilaNumbersExportToExcel]: api.InventoryItems.apiInventoryExportToExcelSerialNumbersGet,
  [GridType.ProductSalesOrdersExportToExcel]: api.Products.apiProductsExportProductDetailsSalesOrdersToExcelGet,
  [GridType.ProductSalesOrderCustomersExportToExcel]: api.Products.apiProductsExportProductDetailsCustomersToExcelGet,
  [GridType.ProductExpirationDates]: api.Products.apiProductsQueryProductExpirationDatesGet,
  [GridType.ProductExpirationDatesExportToExcel]: api.Products.apiProductsExportProductExpirationDatesToExcelGet,
  [GridType.ProductSerialNumbers]: api.Products.apiProductsQueryProductSerialNumbersGet,
  [GridType.ProductSerialNumbersExportToExcel]: api.Products.apiProductsExportProductSerialNumbersToExcelGet,
  [GridType.ProductReceivingOrdersExportToExcel]: api.Products.apiProductsExportProductDetailsReceivingOrdersToExcelGet,
  [GridType.CustomerSalesOrdersExportToExcel]: api.Customers.apiCustomersExportSalesOrdersToExcelGet,
  [GridType.CustomerSalesOrderLineItemsExportToExcel]: api.Customers.apiCustomersExportLineItemsToExcelGet,
  [GridType.SalesOrderSerialNumbers]: api.SalesOrders.apiSalesOrdersQuerySalesOrderSerialNumbersGet,
  [GridType.CustomerShippingAddressesExportToExcel]: api.Customers.apiCustomersExportAddressesToExcelGet,
  [GridType.SalesOrderPackageLineItemsExportToExcel]: api.SalesOrders.apiSalesOrdersExportSalesOrderPackageLineItemsToExcelGet,
  [GridType.SalesOrderSerialNumbersExportToExcel]: api.SalesOrders.apiSalesOrdersExportSalesOrderSerialNumbersToExcelGet,
  [GridType.SalesOrderLineItemsExportToExcel]: api.SalesOrders.apiSalesOrdersExportSalesOrderLineItemsToExcelGet,
  [GridType.ReceivingOrderLineItemExpirationDatesExportToExcel]: api.ReceivingOrders.apiReceivingOrdersExportToExcelLineItemExpirationDatesGet,
  [GridType.ReceivingOrderLineItemSerialNumbers]: api.ReceivingOrders.apiReceivingOrdersQueryLineItemSerialNumbersGet,
  [GridType.ReceivingOrderLineItemSerialNumbersExportToExcel]: api.ReceivingOrders.apiReceivingOrdersExportToExcelLineItemSerialNumbersGet,
};

function addAuthToHeader() {
  return {
    headers: getAuthHeaders(),
  };
}

export const getResource = (func: Function) => {
  return (params: RequestParams) => {
    if (params) {
      const parameters = Object.keys(params).map(x => {
        return params[x];
      });
      return func(...parameters, addAuthToHeader());
    }
    return func(addAuthToHeader());
  };
};

export const resourceApiMap = {
  /* Admin */
  [ResourceType.CreateTenant]: getResource(api.Tenants.adminTenantsCreatePost),
  [ResourceType.GetAllTenants]: getResource(api.Tenants.apiTenantsAllGet),
  [ResourceType.SalesOrderDetails]: getResource(api.SalesOrders.apiSalesOrdersDetailGet),
  [ResourceType.InvalidSalesOrderDetails]: getResource(api.SalesOrders.apiSalesOrdersInvalidSalesOrderDetailGet),
  [ResourceType.CustomerDetails]: getResource(api.Customers.apiCustomersDetailGet),
  [ResourceType.SalesOrderLongTermReport]: getResource(api.SalesOrders.apiSalesOrdersLongTermReportGet),
  [ResourceType.SalesOrderWeeklyReport]: getResource(api.SalesOrders.apiSalesOrdersWeeklyReportGet),
  [ResourceType.SendReceivingOrders]: getResource(api.ReceivingOrders.apiReceivingOrdersCreatePost),
  [ResourceType.CreateSalesOrder]: getResource(api.SalesOrders.apiSalesOrdersCreatePost),
  [ResourceType.LastSyncTime]: getResource(api.Tenants.apiTenantsLastSyncTimeGet),
  [ResourceType.SendFeedback]: getResource(api.Feedback.apiUserFeedbackPost),
  [ResourceType.CreateIntegration]: getResource(api.Integrations.apiIntegrationsCreatePost),
  [ResourceType.GetIntegrations]: getResource(api.Integrations.apiIntegrationsAllGet),
  [ResourceType.GetIntegrationPayload]: getResource(api.Integrations.apiIntegrationsGetPayloadGet),
  [ResourceType.UpdateIntegration]: getResource(api.Integrations.apiIntegrationsUpdatePut),
  [ResourceType.DeleteIntegration]: getResource(api.Integrations.apiIntegrationsDeleteDelete),
  [ResourceType.AddNewCustomerShippingAddress]: getResource(api.Customers.apiCustomersAddAddressPost),
  [ResourceType.SearchCustomers]: getResource(api.Customers.apiCustomersSearchGet),
  [ResourceType.CustomerInfo]: getResource(api.Customers.apiCustomersInfoGet),
  [ResourceType.UpdateCustomerInfo]: getResource(api.Customers.apiCustomersUpdateInfoPut),
  [ResourceType.UpdateCustomerShippingAddress]: getResource(api.Customers.apiCustomersUpdateAddressPut),
  [ResourceType.ReturnsLongTermReport]: getResource(api.Returns.apiReturnsLongTermReportGet),
  [ResourceType.ReturnsWeeklyReport]: getResource(api.Returns.apiReturnsWeeklyReportGet),
  [ResourceType.SalesOrderOfReturn]: getResource(api.Returns.apiReturnsSalesOrderGet),
  [ResourceType.ReturnDetails]: getResource(api.Returns.apiReturnsDetailGet),
  [ResourceType.CustomerShippingAddresses]: getResource(api.Customers.apiCustomersAddressesGet),
  [ResourceType.SyncManualIntegration]: getResource(api.Integrations.apiIntegrationsSyncPut),
  [ResourceType.CreateCustomer]: getResource(api.Customers.apiCustomersCreatePost),
  [ResourceType.CreateReturn]: getResource(api.Returns.apiReturnsCreatePost),
  [ResourceType.PipelineStateOfReceivingOrder]: getResource(api.ReceivingOrders.apiReceivingOrdersStateDetailGet),
  [ResourceType.PipelineStateOfSalesOrder]: getResource(api.SalesOrders.apiSalesOrdersStateDetailGet),
  [ResourceType.PipelineStateOfReturn]: getResource(api.Returns.apiReturnsStateDetailGet),
  [ResourceType.ProductDetails]: getResource(api.Products.apiProductsDetailGet),
  [ResourceType.ReceivingOrderDetails]: getResource(api.ReceivingOrders.apiReceivingOrdersDetailGet),
  [ResourceType.UpdateProductFavorite]: getResource(api.Products.apiProductsUpdateFavoritePut),
  [ResourceType.UpdateReceivingOrderTransferDetails]: getResource(
    api.ReceivingOrders.apiReceivingOrdersUpdateTransferDetailPut
  ),
  [ResourceType.ProductFavorite]: getResource(api.Products.apiProductsFavoriteGet),
  [ResourceType.CreateUser]: getResource(api.Users.apiUsersCreatePost),
  [ResourceType.ChangeUserType]: getResource(api.Users.apiUsersChangeRolePost),
  [ResourceType.CreateProduct]: getResource(api.Products.apiProductsCreatePost),
  [ResourceType.UpdateProduct]: getResource(api.Products.apiProductsUpdatePost),
  [ResourceType.GetUserDetail]: getResource(api.Users.apiUsersDetailGet),
  [ResourceType.DeleteUser]: getResource(api.Users.apiUsersDeleteDelete),
  [ResourceType.GetCompanyDetail]: getResource(api.Tenants.apiTenantsCompanyGet),
  [ResourceType.ChangeUserInfo]: getResource(api.Users.apiUsersUpdatePut),
  [ResourceType.ChangeCompanyInfo]: getResource(api.Tenants.apiTenantsUpdateCompanyPut),
  [ResourceType.CancelSalesOrder]: getResource(api.SalesOrders.apiSalesOrdersCancelPut),
  [ResourceType.GetTenantDetail]: getResource(api.Tenants.adminTenantsDetailGet),
  [ResourceType.GetTenantSummary]: getResource(api.Tenants.adminTenantsSummaryGet),
  [ResourceType.GetTenantDetailedInfo]: getResource(api.Tenants.adminTenantsQueryTenantDetailsGet),
  [ResourceType.GetTenantIntegrations]: getResource(api.Integrations.adminIntegrationsAllGet),
  [ResourceType.AllCountries]: getResource(api.Province.apiProvinceCountriesGet),
  [ResourceType.AllProvinces]: getResource(api.Province.apiProvinceAllGet),
  [ResourceType.DistrictsOfProvince]: getResource(api.Province.apiProvinceDistrictsGet),
  [ResourceType.UpdateLastSyncTimeForIntegration]: getResource(api.Integrations.adminIntegrationsUpdateLastSyncTimePut),
  [ResourceType.SendProductsToWMSFromOmnitro]: getResource(api.Products.adminProductsSendtoWMSPut),
  [ResourceType.SalesOrderPackages]: getResource(api.SalesOrders.apiSalesOrdersQueryPackagesGet),
  [ResourceType.SalesOrderPackageQuery]: getResource(api.SalesOrders.apiSalesOrdersQueryPackageLineItemsGet),
  [ResourceType.SalesOrderLineItems]: getResource(api.SalesOrders.apiSalesOrdersQueryLineItemsGet),
  [ResourceType.InvalidSalesOrderLineItems]: getResource(
    api.SalesOrders.apiSalesOrdersQueryInvalidSalesOrderLineItemsGet
  ),
  [ResourceType.UpdateCargoInfo]: getResource(api.SalesOrders.apiSalesOrdersUpdateCargoInfoPatch),
  [ResourceType.CreateSalesOrderPackage]: getResource(api.SalesOrders.apiSalesOrdersCreatePackagePost),
  [ResourceType.CancelSalesOrderPackage]: getResource(api.SalesOrders.apiSalesOrdersCancelPackagePut),
  [ResourceType.CancelSalesOrderLineItem]: getResource(api.SalesOrders.apiSalesOrdersCancelLineItemsPut),
  [ResourceType.ReturnCountBySalesOrder]: getResource(api.Returns.apiReturnsCountBySalesOrderGet),
  [ResourceType.RenewContract]: getResource(api.Tenants.adminTenantsUpdatePut),
  [ResourceType.BulkForceCancelSalesOrders]: getResource(api.SalesOrders.apiSalesOrdersBulkForceCancelPut),
  [ResourceType.BulkSoftDeleteSalesOrders]: getResource(api.SalesOrders.adminSalesOrdersBulkSoftDeletePut),
  [ResourceType.SyncFromIntegrationUnfulfilled]: getResource(
    api.SalesOrders.adminSalesOrdersSyncFromIntegrationUnfulfilledPut
  ),
  [ResourceType.SyncFromIntegrationCancelled]: getResource(
    api.SalesOrders.adminSalesOrdersSyncFromIntegrationCancelledPut
  ),
  [ResourceType.CorrectInvalidSalesOrdersLineItemNotFound]: getResource(api.SalesOrders.adminSalesOrdersCorrectInvalidSalesOrdersLineItemNotFoundQueueMessageAddGet),
  [ResourceType.CleanMonthlyInvalidSalesOrders]: getResource(api.SalesOrders.adminSalesOrdersCleanMonthlyInvalidSalesOrdersQueueMessageAddGet),
  [ResourceType.BulkUndoCancelSalesOrders]: getResource(api.SalesOrders.adminSalesOrdersBulkUndoCancelPut),
  [ResourceType.ReceivingOrdersBulkSoftDelete]: getResource(api.ReceivingOrders.adminReceivingOrdersBulkSoftDeletePut),
  [ResourceType.ReceivingOrdersResetWmsSync]: getResource(api.ReceivingOrders.adminReceivingOrdersResetWmsSyncPut),
  [ResourceType.PackagesBulkResetFulfillmentSync]: getResource(api.Packages.adminPackagesBulkResetFulfillmentSyncPut),
  [ResourceType.PackageBulkResetWmsSync]: getResource(api.Packages.adminPackagesBulkResetWmsSyncPut),
  [ResourceType.PackagesSyncFromWms]: getResource(api.Packages.adminPackagesSyncFromWmsPut),
  [ResourceType.SalesOrderExcelExport]: getResource(api.SalesOrders.apiSalesOrdersExportToExcelGet),
  [ResourceType.SalesOrderExcelDetailedExport]: getResource(api.SalesOrders.apiSalesOrdersExportToExcelWithDetailGet),
  [ResourceType.PendingSalesOrderExcelExport]: getResource(
    api.SalesOrders.apiSalesOrdersExportPendingdSalesOrderToExcelGet
  ),
  [ResourceType.PendingSalesOrderExcelDetailedExport]: getResource(
    api.SalesOrders.apiSalesOrdersExportPendingdSalesOrderWithDetailToExcelGet
  ),
  [ResourceType.ReturnsExcelExport]: getResource(api.Returns.apiReturnsExportToExcelGet),
  [ResourceType.ReturnsExcelDetailedExport]: getResource(api.Returns.apiReturnsExportToExcelWithDetailGet),
  [ResourceType.ReceivingOrdersExcelExport]: getResource(api.ReceivingOrders.apiReceivingOrdersExportToExcelGet),
  [ResourceType.ReceivingOrdersDetailedExcelExport]: getResource(
    api.ReceivingOrders.apiReceivingOrdersExportToExcelWithDetailGet
  ),
  [ResourceType.CargoCarriers]: getResource(api.CargoCarriers.apiCargoCarriersGet),
  [ResourceType.SalesChannels]: getResource(api.SalesChannels.apiSalesChannelsGet),
  [ResourceType.TenantGetDetails]: getResource(api.Tenants.apiTenantsDetailsGet),
  [ResourceType.CorrectInvalidSalesOrder]: getResource(api.SalesOrders.apiSalesOrdersCorrectInvalidSalesOrderPost),
};

export function getGridData(
  query: string,
  gridKey: string,
  apiArgs?: Array<Record<string, any>>
): Promise<GridFetchResult> {
  if (apiArgs) {
    return gridApiMap[gridKey](...apiArgs, query, addAuthToHeader());
  }
  return gridApiMap[gridKey](query, addAuthToHeader());
}

export function postExcelGridData(
  query: string,
  gridKey: string,
  apiArgs?: Array<Record<string, any>>
): Promise<GridFetchResult> {
  let postFunction = gridApiMap[gridKey + 'ExportToExcel'];
  if (apiArgs) {
    return postFunction(...apiArgs, query);
  }
  return postFunction(query);
}

export const validUserId = 'validUserId';

export type RequestParams = Dictionary<any>;

export function uploadImage(params: RequestParams) {
  const endpoint = `${url}/api/Files.upload`;

  const data = new FormData();
  data.append('file', params.file);

  const { headers } = addAuthToHeader();

  const options = {
    method: 'POST',
    mode: 'cors' as RequestMode,
    body: data,
    headers,
  };

  return fetch(endpoint, options).then(response => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }
    throw response;
  });
}
