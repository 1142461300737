export enum ResourceType {
  SalesOrderDetails = 'salesOrderDetails',
  InvalidSalesOrderDetails = 'invalidSalesOrderDetails',
  CorrectInvalidSalesOrder = 'correctInvalidSalesOrder',
  SalesOrderPackages = 'salesOrderPackages',
  SalesOrderPackageQuery = 'salesOrderPackageQuery',
  SalesOrderLineItems = 'salesOrderLineItems',
  SalesOrderSerialNumbers = 'salesOrderSerialNumbers',
  InvalidSalesOrderLineItems = 'invalidSalesOrderLineItems',
  CreateSalesOrderPackage = 'createSalesOrderPackage',
  UpdateCargoInfo = 'updateCargoInfo',
  CustomerDetails = 'customerDetails',
  SalesOrderWeeklyReport = 'salesOrderWeeklyReport',
  SalesOrderLongTermReport = 'salesOrderLongTermReport',
  SendReceivingOrders = 'sendReceivingOrders',
  CreateSalesOrder = 'createSalesOrder',
  LastSyncTime = 'lastSyncTime',
  SendFeedback = 'sendFeedback',
  SearchCustomers = 'searchCustomers',
  CreateIntegration = 'createIntegration',
  GetIntegrations = 'getIntegrations',
  GetIntegrationPayload = 'getIntegrationPayload',
  UpdateIntegration = 'updateIntegration',
  DeleteIntegration = 'deleteIntegration',
  AddNewCustomerShippingAddress = 'addNewCustomerShippingAddress',
  CustomerInfo = 'getCustomerInfo',
  UpdateCustomerInfo = 'updateGetCustomerInfo',
  ReturnsLongTermReport = 'returnsLongTermReport',
  ReturnsWeeklyReport = 'returnsWeeklyReport',
  ReturnDetails = 'returnDetails',
  ProductDetails = 'productDetails',
  ProductFavorite = 'productFavorite',
  ReceivingOrderDetails = 'receivingOrderDetails',
  UpdateReceivingOrderTransferDetails = 'updateReceivingOrderTransferDetails',
  UpdateProductFavorite = 'updateProductFavorite',
  SalesOrderOfReturn = 'salesOrderOfReturn',
  CustomerShippingAddresses = 'getCustomerShippingAddress',
  UpdateCustomerShippingAddress = 'updateCustomerShippingAddress',
  SyncManualIntegration = 'syncManualIntegration',
  CreateCustomer = 'createCustomer',
  CreateReturn = 'createReturn',
  GetAllTenants = 'getAllTenants',
  PipelineStateOfSalesOrder = 'pipelineStateOfSalesOrder',
  PipelineStateOfReceivingOrder = 'pipelineStateOfReceivingOrder',
  PipelineStateOfReturn = 'pipelineStateOfReturn',
  CreateUser = 'createUser',
  ChangeUserType = 'changeUserType',
  CreateTenant = 'createTenant',
  CreateProduct = 'createProduct',
  UpdateProduct = 'updateProduct',
  AcceptToWarehouse = 'acceptToWarehouse',
  CompleteReturn = 'completeReturn',
  UpdateLastSyncTimeForIntegration = 'updateLastSyncTimeForIntegration',
  GetUserDetail = 'getUserDetail',
  DeleteUser = 'deleteUser',
  GetCompanyDetail = 'getCompanyDetail',
  ChangeUserInfo = 'changeUserInfo',
  ChangeCompanyInfo = 'changeCompanyInfo',
  CancelSalesOrder = 'cancelSalesOrder',
  GetTenantDetail = 'getTenantDetail',
  GetTenantDetailedInfo = 'GetTenantDetailedInfo',
  GetTenantSummary = 'getTenantSummary',
  GetTenantIntegrations = 'getTenantIntegrations',
  GetTenantProducts = 'getTenantProducts',
  RenewContract = 'renewContract',
  AllProvinces = 'allProvinces',
  AllCountries = 'allCountries',
  DistrictsOfProvince = 'districtsOfProvince',
  SendProductsToWMSFromOmnitro = 'sendProductsToWMSFromOmnitro',
  CancelSalesOrderPackage = 'cancelSalesOrderPackage',
  CancelSalesOrderLineItem = 'cancelSalesOrderLineItem',
  ReturnCountBySalesOrder = 'returnCountBySalesOrder',
  BulkForceCancelSalesOrders = 'bulkForceCancelSalesOrders',
  BulkSoftDeleteSalesOrders = 'bulkSoftDeleteSalesOrders',
  BulkUndoCancelSalesOrders = 'bulkUndoCancelSalesOrders',
  SyncFromIntegrationUnfulfilled = 'syncFromIntegrationUnfulfilled',
  SyncFromIntegrationCancelled = 'syncFromIntegrationCancelled',
  ReceivingOrdersBulkSoftDelete = 'receivingOrdersBulkSoftDelete',
  ReceivingOrdersResetWmsSync = 'receivingOrdersResetWmsSync',
  PackagesBulkResetFulfillmentSync = 'packagesBulkResetFulfillmentSync',
  PackageBulkResetWmsSync = 'packageBulkResetWmsSync',
  PackagesSyncFromWms = 'packagesSyncFromWms',
  SalesOrderExcelExport = 'salesOrderExport',
  SalesOrderExcelDetailedExport = 'salesOrderDetailedExport',
  PendingSalesOrderExcelExport = 'pendingSalesOrderExport',
  PendingSalesOrderExcelDetailedExport = 'pendingSalesOrderDetailedExport',
  ReturnsExcelExport = 'returnsExcelExport',
  ReturnsExcelDetailedExport = 'returnsExcelDetailedExport',
  ReceivingOrdersExcelExport = 'receivingOrdersExcelExport',
  ReceivingOrdersDetailedExcelExport = 'receivingOrdersDetailedExcelExport',
  CargoCarriers = 'cargoCarriers',
  SalesChannels = 'salesChannels',
  TenantGetDetails = 'tenantGetDetails',
  CleanMonthlyInvalidSalesOrders = 'cleanMonthlyInvalidSalesOrders',
  CorrectInvalidSalesOrdersLineItemNotFound = 'correctInvalidSalesOrdersLineItemNotFound'
}
