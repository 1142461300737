import ExcelUploader from '@components/atoms/ExcelUploader/ExcelUploader';
import { config } from '@config';
import { StatusTypes } from '@oplog/express';
import { getAuthHeaders } from '@services';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'FileUploader';

export interface FileUploaderProps extends Props {
  apiUrlExtension: string;
  fileType: string;
  uploadFileType: string;
  onClose: () => void;
  onSuccess?: (response: any) => void;
  customFileTemplateKey?: string;
}

export interface FileUploaderState {
  uploadError?: ErrorModel;
  errorTexts: (string | JSX.Element)[];
  errorType: any;
}

export enum ExcelUploadErrors {
  InvalidContentType = 'InvalidContentType',
  EmptyField = 'EmptyField',
  HeaderNameNotFound = 'HeaderNameNotFound',
  SheetNameNotFound = 'SheetNameNotFound',
  RowCountError = 'RowCountError',
  ResourceNotFound = 'ResourceNotFound',
  ResourceAlreadyExists = 'ResourceAlreadyExists',
  DuplicateReferenceNumberAtSalesOrdersSheetError = 'DuplicateReferenceNumberAtSalesOrdersSheetError',
  SalesOrderReferenceNumberNotFound = 'SalesOrderReferenceNumberNotFound',
  SKUNotFound = 'SKUNotFound',
  LineItemNotFound = 'LineItemNotFound',
  LineItemQuantityError = 'LineItemQuantityError',
  SalesOrderHasLineItemsWithSameSku = 'SalesOrderHasLineItemsWithSameSku',
  ReceivingOrderCountError = 'ReceivingOrderCountError',
  InvalidReferenceNumber = 'InvalidReferenceNumber',
  ProductBarcodesNotFound = 'ProductBarcodesNotFound',
  ProductImageUrlNotEmpty = 'ProductImageUrlNotEmpty',
  ProductImageUrlNotValid = 'ProductImageUrlNotValid',
  DuplicateSKUAtProductsSheetError = 'DuplicateSKUAtProductsSheetError',
  WrongProductTypeError = 'WrongProductTypeError',
  WrongDecimalNumberFormatError = 'WrongDecimalNumberFormatError',
  CargoCarrierShouldBeUpsWhenCargoCodeNull = 'CargoCarrierShouldBeUpsWhenCargoCodeNull',
  CargoCarrierShouldNotBeEmptyWhenCargoCodeNotNull = 'CargoCarrierShouldNotBeEmptyWhenCargoCodeNotNull',
  CargoCarrierNotFound = 'CargoCarrierNotFound',
  DuplicateSKUError = 'DuplicateSKUError',
  ReferenceNumberTooLong = 'ReferenceNumberTooLong',
  EmailValidator = 'EmailValidator',
  EmptyAddressOne = 'EmptyAddressOne',
  ReceivingOrderDataNotFound = 'ReceivingOrderDataNotFound',
  ProductDataNotFound = 'ProductDataNotFound',
  SalesOrderDataNotFound = 'SalesOrderDataNotFound',
  SKUNotFoundInProductCatalog = 'SKUNotFoundInProductCatalog',
  ProductBarcodeAlreadyExists = 'ProductBarcodeAlreadyExists',
  ProductSKUAlreadyExists = 'ProductSKUAlreadyExists',
  SameBarcodeForDifferentSkusAtBarcodesSheetError = 'SameBarcodeForDifferentSkusAtBarcodesSheetError',
  ReceivingOrderNonNegativeQuantityError = 'ReceivingOrderNonNegativeQuantityError',
  InvalidSalesOrderReferenceNumber = 'InvalidSalesOrderReferenceNumber',
  InvalidReceivingOrderReferenceNumber = 'InvalidReceivingOrderReferenceNumber',
  InvalidCountry = 'InvalidCountry',
  InvalidCity = 'InvalidCity',
  InvalidDistrict = 'InvalidDistrict',
  InvalidCargoType = 'InvalidCargoType',
  CargoCodeAndCargoDocumentURLMustBeEmptyWhenCargoTypeIsSameDayDelivery = 'CargoCodeAndCargoDocumentURLMustBeEmptyWhenCargoTypeIsSameDayDelivery',
  EmptyAddressPostalCode = 'EmptyAddressPostalCode',
  InternationalCustomsProductPriceError = 'InternationalCustomsProductPriceError',
  InvalidUrl = 'InvalidUrl',
  GiftWrapType = 'GiftWrapType',
  GiftNotesTooLong = 'GiftNotesTooLong',
  InvalidPaymentOption = 'InvalidPaymentOption',
  InvalidTotalPaymentAmount = 'InvalidTotalPaymentAmount',
  InvalidCurrency = 'InvalidCurrency',
  EmptyExpectedShipmentDate = 'EmptyExpectedShipmentDate',
  InvalidDateFormatExpectedShipmentDate = 'InvalidDateFormatExpectedShipmentDate',
  InvalidDateExpectedShipmentDate = 'InvalidDateExpectedShipmentDate',
  EmptyExternalCustomerId = 'EmptyExternalCustomerId',
  OutOfCoverageSameDayDelivery = "OutOfCoverageSameDayDelivery",
  SameDayDeliveryNotEnabled = "SameDayDeliveryNotEnabled",
  InvalidBooleanValueError = "InvalidBooleanValueError",
  WrongDecimalNumberFormatProductCatalogError = "WrongDecimalNumberFormatProductCatalogError",
  InvalidBooleanValueProductCatalogError = "InvalidBooleanValueProductCatalogError"
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  intl,
  apiUrlExtension,
  fileType,
  onClose,
  uploadFileType,
  onSuccess,
  customFileTemplateKey
}) => {
  const [errorText, setErrorText] = useState([]);
  const [errorType, setErrorType] = useState<any>(undefined);
  const [uploadError, setUploadError] = useState<any>(undefined);

  const onError = (errorList: any) => {
    const errorStatusType =
      errorList.responseData?.error?.errors?.[0].errorCode == 'NotEmptyValidator'
        ? StatusTypes.Error
        : StatusTypes.Warning;

    setUploadError(errorList.responseData?.error);
    setErrorType(errorStatusType);
  };

  useEffect(() => {
    if (uploadError) setErrorTexts();
  }, [uploadError]);

  const setErrorTexts = () => {
    const texts = uploadError?.errors
      ? uploadError.errors.map((error: any) => {
        switch (error.errorCode) {
          case ExcelUploadErrors.EmptyField:
            const errorMessageArray = error.message.split(':');
            const sheetName = errorMessageArray[0];
            const fieldName = errorMessageArray[1];
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  sheetName: <b>{sheetName}</b>,
                  fieldName: <b>{fieldName}</b>,
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.HeaderNameNotFound:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  headerName: error.message,
                  sheetName: error.property,
                }}
              />
            );
          case ExcelUploadErrors.InvalidContentType:
            return intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`];
          case ExcelUploadErrors.ReceivingOrderCountError:
            return intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`];
          case ExcelUploadErrors.InvalidReferenceNumber:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  referenceNumber: <b>{error.message}</b>,
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.ProductBarcodesNotFound:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  sku: <b>{error.message}</b>,
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
            case ExcelUploadErrors.ProductImageUrlNotEmpty:
              return (
                <FormattedMessage
                  id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                  values={{
                    sku: <b>{error.message}</b>,
                    rowNumber: <b>{error.property}</b>,
                  }}
                />
              );
              case ExcelUploadErrors.ProductImageUrlNotValid:
              return (
                <FormattedMessage
                  id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                  values={{
                    sku: <b>{error.message}</b>,
                    rowNumber: <b>{error.property}</b>,
                  }}
                />
              );
          case ExcelUploadErrors.ResourceNotFound:
            // These are temporary! Waiting for back-end update.
            const splitResourceNotFound = error.message.split('"');
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  propertyName: <strong>{splitResourceNotFound[1]}</strong>,
                  message: splitResourceNotFound[2],
                }}
              />
            );
          case ExcelUploadErrors.ResourceAlreadyExists:
            // These are temporary! Waiting for back-end update.
            const splitResourceAlreadyExists = error.message.split('"');
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  propertyName: <strong>{splitResourceAlreadyExists[1]}</strong>,
                  message: splitResourceAlreadyExists[2],
                }}
              />
            );
          case ExcelUploadErrors.RowCountError:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  sheetName: <b>{error.property}</b>,
                  count: error.message,
                  sheetNameText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.SheetName`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.SheetNameNotFound:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  sheetName: <b>{error.message}</b>,
                  sheetNameText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.SheetName`]}</b>,
                }}
              />
            );

          case ExcelUploadErrors.DuplicateReferenceNumberAtSalesOrdersSheetError:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: error.property,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.SalesOrderReferenceNumberNotFound:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                  rowMessage: <b>{error.message}</b>,
                }}
              />
            );
          case ExcelUploadErrors.SKUNotFound:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  barcodes: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.Barcodes`]}</b>,
                  products: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.Products`]}</b>,
                  sku: error.message,
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.LineItemNotFound:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  referenceNumber: <b>{error.message}</b>,
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.SalesOrderHasLineItemsWithSameSku:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  SKU: <b>{error.message}</b>,
                }}
              />
            );
          case ExcelUploadErrors.DuplicateSKUAtProductsSheetError:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.WrongProductTypeError:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  producyType: error.message,
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.WrongDecimalNumberFormatError:
            // Not the best method btw.
            const errorArray = error.property.split('_');
            const rowNumber = errorArray[0];
            const columnNumber = errorArray[1];

            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  value: error.message,
                  rowNumber: <b>{rowNumber}</b>,
                  columnNumber: <b>{columnNumber}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                  columnNumberText: (
                    <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.ColumnNumber`]}</b>
                  ),
                }}
              />
            );
          case ExcelUploadErrors.LineItemQuantityError:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.CargoCarrierShouldBeUpsWhenCargoCodeNull:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.CargoCarrierShouldNotBeEmptyWhenCargoCodeNotNull:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.CargoCarrierNotFound:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.DuplicateSKUError:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  skuList: <b>{error.message}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.ReferenceNumberTooLong:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.EmailValidator:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );

          case ExcelUploadErrors.ProductDataNotFound:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  sheetName: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.SalesOrderDataNotFound:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  sheetName: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.ReceivingOrderDataNotFound:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  sheetName: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.SKUNotFoundInProductCatalog:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  salesOrderLineItems: (
                    <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.SaleOrderLineItems`]}</b>
                  ),
                  sku: <b>{error.message}</b>,
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                }}
              />
            );
          case ExcelUploadErrors.ProductBarcodeAlreadyExists:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  barcode: <b>{error.message}</b>,
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.ProductSKUAlreadyExists:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  sku: <b>{error.message}</b>,
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.SameBarcodeForDifferentSkusAtBarcodesSheetError:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  barcode: <b>{error.message}</b>,
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.ReceivingOrderNonNegativeQuantityError:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.InvalidSalesOrderReferenceNumber:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.InvalidReceivingOrderReferenceNumber:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.InvalidDistrict:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.InvalidCity:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.InvalidCountry:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.InvalidCargoType:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.EmptyExpectedShipmentDate:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.InvalidDateFormatExpectedShipmentDate:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.InvalidDateExpectedShipmentDate:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.EmptyExternalCustomerId:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.CargoCodeAndCargoDocumentURLMustBeEmptyWhenCargoTypeIsSameDayDelivery:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.EmptyAddressPostalCode:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.EmptyAddressPostalCodeForExcel`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.InternationalCustomsProductPriceError:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.InvalidUrl:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.GiftWrapType:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>
                }}
              />
            );
          case ExcelUploadErrors.GiftNotesTooLong:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>
                }}
              />
            );
          case ExcelUploadErrors.InvalidPaymentOption:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>
                }}
              />
            );
          case ExcelUploadErrors.InvalidTotalPaymentAmount:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>
                }}
              />
            );
          case ExcelUploadErrors.InvalidCurrency:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>
                }}
              />
            );
          case ExcelUploadErrors.OutOfCoverageSameDayDelivery:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  rowNumber: <b>{error.property}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>
                }}
              />
            );
          case ExcelUploadErrors.SameDayDeliveryNotEnabled:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
              />
            );
          case ExcelUploadErrors.InvalidBooleanValueError:
            const errArray = error.property.split('_');
            const rowNum = errArray[0];
            const columnNum = errArray[1];
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  value: error.message,
                  rowNumber: <b>{rowNum}</b>,
                  columnNumber: <b>{columnNum}</b>,
                  rowNumberText: <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.RowNumber`]}</b>,
                  columnNumberText: (
                    <b>{intl.messages[`${COMPONENT_INTL_KEY}.FileUploaderErrorList.ColumnNumber`]}</b>
                  ),
                }}
              />
            );
          case ExcelUploadErrors.WrongDecimalNumberFormatProductCatalogError:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  value: <b>{error.message}</b>,
                  fieldName: <b>{error.property}</b>,
                }}
              />
            );
          case ExcelUploadErrors.InvalidBooleanValueProductCatalogError:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.${error.errorCode}`}
                values={{
                  value: <b>{error.message}</b>,
                  fieldName: <b>{error.property}</b>,
                }}
              />
            );
          default:
            return (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.UnhandledExcelError`}
              />
            );
        }
      })
      : [<FormattedMessage
        id={`${COMPONENT_INTL_KEY}.FileUploaderErrorList.UnhandledExcelError`}
      />];
    setErrorText(texts);
  };

  const excelUploadModalUploaderText = () => {
    const template = intl.messages[`${uploadFileType}.FileUploader.Template`];
    const fileName = customFileTemplateKey
      ? intl.messages[`${uploadFileType}.FileUploader.${customFileTemplateKey}`]
      : intl.messages[`${uploadFileType}.FileUploader.FileTemplateName`];
    const templateLink = `/excelImport/${fileName}.xlsx`;
    return (
      <FormattedMessage
        id={`${uploadFileType}.FileUploader.FileUploadText`}
        values={{
          template: (
            <a onClick={e => e.stopPropagation()} href={templateLink} download>
              {template}
            </a>
          ),
        }}
      />
    );
  };

  const excelUploadModalErrorText = () => {
    const template = intl.messages[`${uploadFileType}.FileUploader.Template`];
    const templateLink = `/excelImport/${intl.messages[`${uploadFileType}.FileUploader.FileTemplateName`]}.xlsx`;
    return (
      <FormattedMessage
        id={`${uploadFileType}.FileUploader.FileUploadError`}
        values={{
          template: (
            <a onClick={e => e.stopPropagation()} href={templateLink} download>
              {template}
            </a>
          ),
        }}
      />
    );
  };

  const url = `${config.api.url}${apiUrlExtension}`;
  const headers = getAuthHeaders();

  return (
    <ExcelUploader
      acceptedFile={fileType}
      onError={onError}
      onClose={onClose}
      onSuccess={onSuccess}
      errors={errorType == StatusTypes.Error ? [] : errorText}
      errorType={errorType}
      texts={{
        uploader: excelUploadModalUploaderText(),
        success: intl.messages[`${uploadFileType}.FileUploader.FileUploadSuccess`],
        error: excelUploadModalErrorText(),
        warning: intl.messages[`${uploadFileType}.FileUploader.FileUploadWarning`],
        loading: intl.messages[`${uploadFileType}.FileUploader.FileUploadWait`],
        browseButton: intl.messages[`${COMPONENT_INTL_KEY}.Browse`],
        closeButton: intl.messages[`${COMPONENT_INTL_KEY}.Close`],
        errorsTitle:
          errorType == StatusTypes.Error
            ? ''
            : intl.messages[`${uploadFileType}.FileUploader.FileUploadErrorListTitle`],
      }}
      api={{ url, headers }}
    />
  );
};
