import { linkFormatter } from '@oplog/data-grid';
import { urls } from '@routes/urls';
import history from '../../history';

const genericLinkFormatter = (route: string, pound: boolean, encode: boolean, ...fields: string[]) =>
  linkFormatter(history, route, pound, encode, ...fields);

export const ProductDetailsLinkFormatter = genericLinkFormatter(
  urls.productDetails.replace('/:tab?', ''),
  false,
  true,
  'sku'
);

export const CustomerDetailsLinkFormatter = genericLinkFormatter(
  urls.customerDetails.replace('/:tab?', ''),
  false,
  false,
  'customerNo'
);

export const SalesOrderDetailsLinkFormatter = genericLinkFormatter(
  urls.salesOrderDetails,
  true,
  true,
  'referenceNumber'
);

export const InvalidSalesOrderDetailsLinkFormatter = genericLinkFormatter(
  urls.invalidSalesOrderDetails,
  true,
  true,
  'referenceNumber'
);

export const ReceivingOrderDetailsLinkFormatter = genericLinkFormatter(
  urls.receivingOrderDetails,
  true,
  false,
  'referenceNumber'
);

export const ReturnDetailsLinkFormatter = genericLinkFormatter(urls.returnDetails, true, false, 'referenceNumber');

// export const TenantDetailsLinkFormatter = linkFormatter(urls.tenantDetails.replace('/:tab?', ''), false, false, 'name');
