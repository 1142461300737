import { config } from '@config';
import { getLocale } from '@i18n/locale';
import { GridType, SendToCreateReturnCartGrid } from '@models';
import {
  ClickableCellState,
  GridStoreState,
  imageViewerState,
  ImageViewerStoreState,
  initialGridState
} from '@oplog/data-grid';
import { FileUploadsState, initialFileUploadsState } from '@oplog/file-uploader';
import { Resource, ResourceStoreState } from '@oplog/resource-redux';
import {
  LineItemOfReturnQueryOutputDTO,
  ProductDetailOutputDTO,
  ReceivingOrderDetailOutputDTO,
  SalesOrderQueryOutputDTO
} from '@services/swagger';
import moment from 'moment';
import { LanguageHelper } from '../utils/language';
import { initialReturnDetailsState, ReturnDetailsState } from './returnDetails';

export const STORE_KEY = 'store.state';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STORE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(STORE_KEY, serializedState);
  } catch (e) {
    // ignore errors
  }
};

export interface ReturnRespondResource<T> extends Resource<T> {
  isReturnRespondResourceGridDisabled?: boolean;
}

export interface CreateSalesOrderResource<T> extends Resource<T> {
  isCreateSalesOrderGridDisabled?: boolean;
}

export interface CreateReturnResource<T> extends Resource<T> {
  salesOrder?: SalesOrderQueryOutputDTO;
}

export interface ProductDetailsResource extends Resource<ProductDetailOutputDTO> {
  sku?: string;
  updateFavoriteError?: ErrorModel;
  updateFavoriteIsBusy: boolean;
}

export interface ReceivingOrderDetailsResource extends Resource<ReceivingOrderDetailOutputDTO> {
  updateTransferDetailsError?: ErrorModel;
}

export interface CustomerDetailsResource {
  isNewAddressOpen: boolean;
}

export interface FeedbackModalResource {
  isOpen: boolean;
  subject?: string;
}

export interface StoreState {
  resources: ResourceStoreState;
  customerDetails: CustomerDetailsResource;
  returnDetails: ReturnDetailsState;
  createReturn: CreateReturnResource<Map<string, SendToCreateReturnCartGrid>>;
  grid: Dictionary<GridStoreState>;
  returnRespond: ReturnRespondResource<Array<LineItemOfReturnQueryOutputDTO>>;
  imageViewer: ImageViewerStoreState;
  clickableCell: ClickableCellState;
  intl: {
    locale: string;
    messages: any;
  };
  files: FileUploadsState;
  feedbackModal: FeedbackModalResource;
  tenantDetails: ResourceStoreState;
  countDetails: ResourceStoreState;
}

const storageState = loadState();

const memoryState: StoreState = {
  resources: {},
  tenantDetails: {},
  countDetails: {},
  grid: {
    [GridType.Products]: { ...initialGridState },
    [GridType.ProductSalesOrders]: { ...initialGridState },
    [GridType.Customers]: { ...initialGridState },
    [GridType.SalesOrders]: { ...initialGridState },
    [GridType.SalesOrderQuarantine]: { ...initialGridState },
    [GridType.SalesOrderBlocked]: { ...initialGridState },
    [GridType.ProductSalesOrderCustomers]: { ...initialGridState },
    [GridType.InventoryItems]: { ...initialGridState },
    [GridType.InventoryQuarantine]: { ...initialGridState },
    [GridType.InventoryItemExpirationDates]: { ...initialGridState },
    [GridType.InventoryItemSerialNumbers]: { ...initialGridState },
    [GridType.CustomerSalesOrders]: { ...initialGridState },
    [GridType.CustomerSalesOrderLineItems]: { ...initialGridState },
    [GridType.SalesOrderLineItems]: { ...initialGridState },
    [GridType.SalesOrderSerialNumbers]: { ...initialGridState },
    [GridType.InvalidSalesOrderLineItems]: { ...initialGridState },
    [GridType.ReceivingOrders]: { ...initialGridState },
    [GridType.ReceivingOrderLineItems]: { ...initialGridState },
    [GridType.ReceivingOrderLineItemExpirationDates]: { ...initialGridState },
    [GridType.Returns]: { ...initialGridState },
    [GridType.ReturnsDamaged]: { ...initialGridState },
    [GridType.ReturnLineItems]: { ...initialGridState },
    [GridType.CustomerShippingAddresses]: { ...initialGridState },
    [GridType.PaymentsDetails]: { ...initialGridState },
    [GridType.Users]: { ...initialGridState },
    [GridType.SalesOrderRecentLineItems]: { ...initialGridState },
    [GridType.SupportProducts]: { ...initialGridState },
    [GridType.SupportReceivingOrders]: { ...initialGridState },
    [GridType.ProductReceivingOrders]: { ...initialGridState },
    [GridType.ProductExpirationDates]: { ...initialGridState },
    [GridType.ProductSerialNumbers]: { ...initialGridState },
    [GridType.SupportSalesOrders]: { ...initialGridState },
    [GridType.SupportPackages]: { ...initialGridState },
    [GridType.SalesOrdersReturnable]: { ...initialGridState },
    [GridType.SalesOrderPackageLineItems]: { ...initialGridState },
    [GridType.ProductQuarantine]: { ...initialGridState },
    [GridType.ReceivingOrderLineItemSerialNumbers]: { ...initialGridState }
  },
  customerDetails: {
    isNewAddressOpen: false,
  },
  returnDetails: { ...initialReturnDetailsState() },

  createReturn: {
    isBusy: false,
    data: new Map<string, SendToCreateReturnCartGrid>(),
  },
  returnRespond: {
    isReturnRespondResourceGridDisabled: false,
    isBusy: false,
    data: new Array<LineItemOfReturnQueryOutputDTO>(),
  },
  imageViewer: imageViewerState,
  clickableCell: {
    refNo: undefined,
  },
  intl: {
    locale: config.i18n.default,
    messages: {},
  },
  files: initialFileUploadsState(),
  feedbackModal: {
    isOpen: false,
  },
};

export const initialState: StoreState = { ...memoryState, ...storageState };

initialState.intl = getLocale(LanguageHelper.getLanguage());
// TODO: Fix this ugly hack
moment.locale(LanguageHelper.getLanguage());
// TODO: also fix this
