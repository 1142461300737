import { GridContainer } from '@components/atoms/GridContainer';
import { Box } from '@oplog/express';
import { ExcelExportModal, ExportTypes } from '@components/molecules/ExcelExportModal';
import {
  bubbleStepFormatter,
  CustomerDetailsLinkFormatter,
  geti18nName,
  SalesOrderDetailsLinkFormatter,
  sourceIconFormatter,
} from '@containers/formatters';
import { GridProps, GridType, ResourceType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  dateTimeFormatter,
  ellipsisFormatter,
  enumFormatter,
  getEnumOptions,
  PredefinedFilter,
} from '@oplog/data-grid';
import { CargoType, PaymentOption, SalesOrderQueryOutputDTO, SalesOrderState } from '@services';
import { EXCEL_EXPORT_LIMIT, EXCEL_EXPORT_SALESORDER_LIMIT, generateIntegrationEnum } from '@utils';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'BlockedGrid';

const blockedGridInitalSort: SortField = new SortField({
  property: 'orderCreatedAt',
  by: SortDirection.DESC,
});

export const blockedGridPredefinedFilters: Array<PredefinedFilter> = [];

interface SalesOrdersGridProps extends GridProps {
  filters: any;
  integrations: any;
  blockedExcelToExport: (resourceType: ResourceType) => void;
  blockedExcelExportResource: any;
  blockedExcelToExportInit: () => void;
  blockedExcelExportDetailedResource: any;
  blockedSalesOrdersPackageDetailExcelExportLimit: number;
}

export const SalesOrderBlockedGrid: React.FC<SalesOrdersGridProps> = ({
  intl,
  titleKey,
  filters,
  integrations,
  blockedExcelToExport,
  blockedExcelToExportInit,
  blockedExcelExportResource,
  blockedExcelExportDetailedResource,
  blockedSalesOrdersPackageDetailExcelExportLimit
}) => {
  const [isBlockedExcelExportModalOpen, setIsBlockedExcelExportModalOpen] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);

  const [excelExportBlockedPackageDetailLimit, setExcelExportBlockedPackageDetailLimit] = React.useState(EXCEL_EXPORT_SALESORDER_LIMIT);

  React.useEffect(() => {
    let blockedSalesOrders = filters.data as SalesOrderQueryOutputDTO[];
    if (blockedSalesOrders.length > 0) {
      setRowCount(blockedSalesOrders[0].totalProductCountInSalesOrder);
    }
  });

  React.useEffect(() => {
    if (blockedSalesOrdersPackageDetailExcelExportLimit) {
      setExcelExportBlockedPackageDetailLimit(blockedSalesOrdersPackageDetailExcelExportLimit);
    }
    else {
      setExcelExportBlockedPackageDetailLimit(EXCEL_EXPORT_SALESORDER_LIMIT);
    }
  });

  const blockedGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('referenceNumber', intl, intlKey),
      key: 'referenceNumber',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: SalesOrderDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('customerEmail', intl, intlKey),
      key: 'customerEmail',
      type: 'string',
      visibility: false,
      filterable: true,
    },
    {
      name: geti18nName('customerPhone', intl, intlKey),
      key: 'customerPhone',
      type: 'string',
      visibility: false,
      filterable: true,
    },
    {
      name: geti18nName('integrationType', intl, intlKey),
      key: 'integrationName',
      locked: true,
      sortable: true,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      width: ColumnSize.Large,
      formatter: row => sourceIconFormatter(row.value, row.dependentValues, intl),
      getRowMetaData: (row: DataGridRow) => row,
      options: integrations ? generateIntegrationEnum(integrations) : [],
    },
    {
      name: geti18nName('salesChannel', intl, intlKey),
      key: 'salesChannel',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
      getRowMetaData: (row: DataGridRow) => row['integrationType'],
    },
    {
      name: geti18nName('state', intl, intlKey),
      key: 'state',
      locked: true,
      sortable: true,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: bubbleStepFormatter,
      getRowMetaData: (row: DataGridRow) => {
        return {
          intl,
          enum: SalesOrderState,
          row,
        };
      },
      resizable: true,
      options: getEnumOptions(intl, SalesOrderState),
    },
    {
      name: geti18nName('cargoType', intl, intlKey),
      key: 'cargoType',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      getRowMetaData: () => {
        return intl;
      },
      resizable: true,
      options: getEnumOptions(intl, CargoType),
    },
    {
      name: geti18nName('customerFullName', intl, intlKey),
      key: 'customerFullName',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: CustomerDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
      resizable: true,
    },
    {
      name: geti18nName('shippingAddressCity', intl, intlKey),
      key: 'shippingAddressCity',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: ellipsisFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('paymentOption', intl, intlKey),
      key: 'paymentOption',
      locked: true,
      sortable: true,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      options: getEnumOptions(intl, PaymentOption),
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('uniqueProductCount', intl, intlKey),
      key: 'uniqueProductCount',
      locked: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('totalProductCount', intl, intlKey),
      key: 'totalProductCount',
      locked: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('orderCreatedAt', intl, intlKey),
      key: 'orderCreatedAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
  ];

  const toggleBlockedExcelExportModal = () => {
    setIsBlockedExcelExportModalOpen(true);
  };

  const onBlockedModalClose = () => {
    setIsBlockedExcelExportModalOpen(false);
  };

  return (
    <Box>
      <GridContainer
        titleKey={
          titleKey
            ? `${intl.messages['SalesOrders.Tabs.Blocked']} - ${titleKey}`
            : intl.messages['SalesOrders.Tabs.Blocked']
        }
        intlKey={intlKey}
        remoteExportToExcel
        onExcelExportButtonClick={toggleBlockedExcelExportModal}
        gridKey={GridType.SalesOrderBlocked}
        columns={blockedGridColumns}
        predefinedFilters={blockedGridPredefinedFilters}
        intl={intl}
        sortField={blockedGridInitalSort}
      />
      {isBlockedExcelExportModalOpen && (
        <ExcelExportModal
          excelToExport={blockedExcelToExport}
          intl={intl}
          onModalClose={onBlockedModalClose}
          excelExportResource={blockedExcelExportResource}
          excelExportDetailedResource={blockedExcelExportDetailedResource}
          excelToExportInit={blockedExcelToExportInit}
          excelExportType={ExportTypes.SalesOrderBlocked}
          rowCount={rowCount}
          defaultExportLimit={EXCEL_EXPORT_LIMIT}
          detailedExportLimit={excelExportBlockedPackageDetailLimit}
        />
      )}
    </Box>
  );
};
